import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Phone = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
      <label htmlFor={name}>{required ? `${placeholder} *` : placeholder}</label>
      <input name={slugify(name)} type="phone" placeholder={placeholder} required={required ? true : false} onChange={handleChange} value={value[slugify(name)] ? value[slugify(name)] : ''} />
    </div>
  )
}
