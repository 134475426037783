import { useStaticQuery, graphql } from "gatsby"

export const useGlobalCTA = () => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query GlobalCTA {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                globalCTA {
                  content
                  image {
                    source_url
                  }
                  showLogo
                  subTitle
                  title
                }
              }
            }
          }
        }
      }
    `
  )
  return allWordpressAcfOptions.edges[0].node.options.globalCTA
}