import React, { useState, useEffect } from 'react'
import * as yup from 'yup' // for everything

export const FirstStep = props => {
  let schema = yup.object().shape({
    helpMe: yup.string().required('Required'),
    borrow: yup.string().required('Required'),
    houseValue: yup.string().required('Required'),
    helpWith: yup.string().required('Required'),
  })

  const { lead, nextStep, handleChange, uniqueFieldId } = props

  const [errorStack, setErrorStack] = useState({
    helpMe: false,
    borrow: false,
    houseValue: false,
    helpWith: false,
  })

  const { helpMe, borrow, houseValue, helpWith } = lead

  function setErrorsToFalse() {
    setErrorStack({
      helpMe: false,
      borrow: false,
      houseValue: false,
      helpWith: false,
    })
  }

  const [errorsActive, setErrorsActive] = useState(false)

  const handleNext = e => {
    e.preventDefault()
    schema
      .validate(
        {
          helpMe,
          borrow,
          houseValue,
          helpWith,
        },
        { abortEarly: false }
      )
      .then(valid => {
        if (valid) {
          setErrorsActive(false)
          nextStep()
        }
      })
      .catch(error => {
        setErrorsActive(true)
        setErrorsToFalse()
        error.inner.map(err => {
          let stateValue = err.params.path
          setErrorStack(prevState => ({ ...prevState, [stateValue]: true }))
        })
      })
  }

  const errorMessage = `This field is required!`

  return (
    <div className="form-inner">
      <div className={errorsActive && errorStack.helpMe ? 'errors-active' : ''}>
        {errorsActive && errorStack.helpMe && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`helpMe-${uniqueFieldId}`}>
          I would like Loan Monster to help me:*
        </label>
        <select
          id={`helpMe-${uniqueFieldId}`}
          value={lead?.helpMe}
          onChange={handleChange}
          name="helpMe"
          required
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="Buy my first home">Buy my first home</option>
          <option value="Refinance an existing loan">
            Refinance an existing loan
          </option>
          <option value="Buy an investment">Buy an investment</option>
          <option value="Build / Buy a new home">Build / Buy a new home</option>
          <option value="Business Loan Enquiry">Business Loan Enquiry</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className={errorsActive && errorStack.borrow ? 'errors-active' : ''}>
        {errorsActive && errorStack.borrow && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`borrow-${uniqueFieldId}`}>
          I would like to borrow:*
        </label>
        <select
          id={`borrow-${uniqueFieldId}`}
          value={lead?.borrow}
          onChange={handleChange}
          name="borrow"
          required
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="$200,000 - $300,000">$200,000 - $300,000</option>
          <option value="$300,000 - $400,000">$300,000 - $400,000</option>
          <option value="$400,000 - $500,000">$400,000 - $500,000</option>
          <option value="$500,000 - $750,000">$500,000 - $750,000</option>
          <option value="$750,000 - $1,000,000">$750,000 - $1,000,000</option>
          <option value="$1,000,000 +">$1,000,000 +</option>
        </select>
      </div>
      <div
        className={errorsActive && errorStack.houseValue ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.houseValue && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`houseValue-${uniqueFieldId}`}>
          On a house that has a value of:*
        </label>
        <select
          id={`houseValue-${uniqueFieldId}`}
          value={lead?.houseValue}
          onChange={handleChange}
          name="houseValue"
          required
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="$200,000 - $300,000">$200,000 - $300,000</option>
          <option value="$300,000 - $400,000">$300,000 - $400,000</option>
          <option value="$400,000 - $500,000">$400,000 - $500,000</option>
          <option value="$500,000 - $750,000">$500,000 - $750,000</option>
          <option value="$750,000 - $1,000,000">$750,000 - $1,000,000</option>
          <option value="$1,000,000 +">$1,000,000 +</option>
        </select>
      </div>
      <div
        className={errorsActive && errorStack.helpWith ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.helpWith && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`helpWith-${uniqueFieldId}`}>
          I would like your help with this:*
        </label>
        <select
          id={`helpWith-${uniqueFieldId}`}
          value={lead?.helpWith}
          onChange={handleChange}
          name="helpWith"
          required
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="Immediately">Immediately</option>
          <option value="Within 3 months">Within 3 months</option>
          <option value="In the next 6 months">In the next 6 months</option>
          <option value="6 months +">6 months +</option>
        </select>
      </div>
      <div className="multi-form--button-wrapper">
        <a className="button action" onClick={e => handleNext(e)}>
          Next
        </a>
      </div>
    </div>
  )
}
