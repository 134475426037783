import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import { navigate } from 'gatsby'
import './PostListNavigation.scss'

export const PostListNavigation = (props) => {
    const { pathPrefix, categoryFilter } = props;

    const { nodes: posts } = categoryFilter
    
    let categoriesArray = []
    posts && posts.map(post =>
      post.categories && post.categories.map( category => {
        let thisCompare = categoriesArray.find(compare => compare.wordpress_id === category.wordpress_id)
        !thisCompare && category.slug !== 'uncategorized' && categoriesArray.push(category)
      })
    )

    const handleClick = (path, isRoot) => {
      if (!path) return null;
      navigate(
        path,
        {
          state: {
            // We don't want our scroll update to be ignored on the root category
            disableScrollUpdate: isRoot ? false : true, 
          },
        }
      )
    }
    
    return (
      <div className="post-list-navigation">
        <div className="inner">
          <ul>
            <li onClick={() => handleClick(`/${pathPrefix}`, true)}className="root-category"><GatsbyLink to={`/${pathPrefix}`} activeClassName="active">All</GatsbyLink></li>
            {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorised' && (
              <li onClick={() => handleClick(`/${pathPrefix}${category.slug}/`)} className="category-filter-li" key={index}><GatsbyLink to={`/${pathPrefix}${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }}/></li>
            ))}
          </ul>
        </div>
      </div>
    )
}
