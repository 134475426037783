import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'
import { Image } from '../../Image'
import { decodeEntities } from '../../../utils/helpers'
import './PostListItem.scss'

export const PostListItem = props => {
  const { data, pathPrefix } = props
  const { excerpt, title, slug, featured_image_url, date } = data
  return (
    <div className="post-list-item">
      <div className="post-list-item__inner">
        <div className="image">
          <GatsbyLink to={`/${pathPrefix}${slug}/`} label={title}>
            <Image src={featured_image_url} className="background" />
          </GatsbyLink>
        </div>
        <div className="inner">
          <h4 className="title">
            <GatsbyLink to={`/${pathPrefix}${slug}/`}>
              {decodeEntities(title)}
            </GatsbyLink>
          </h4>
          {excerpt && <RenderContent content={`${excerpt}...`} />}
          {slug && (
            <GatsbyLink
              to={`/${pathPrefix}${slug}/`}
              label={title}
              className="post-link"
            >
              Read More
            </GatsbyLink>
          )}
          {date && (
            <div className="meta">
              <span className="date">{date}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
