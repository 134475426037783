import React, { Component } from 'react'
import { FirstStep } from './FirstStep'
import { SecondStep } from './SecondStep'
import { ThirdStep } from './ThirdStep'
class MultiStepForm extends Component {
  state = {
    step: 1,
    helpMe: '',
    borrow: '',
    houseValue: '',
    helpWith: '',
    loanSeek: '',
    annualIncome: '',
    //creditHistory: '',
    currentJob: '',
    currentlyWork: '',
    fullName: '',
    // firstName: '',
    // lastName: '',
    emailTo: '',
    bestTime: '',
    contactNumber: '',
    postcode: '',
    uniqueId: '',
  }

  componentDidMount() {

    // Generate a unique number for our form elements.
    // Chance of collision is low enough for practical use cases.
    const uid = function() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
    this.setState({uniqueId: uid()})
  }
  
  nextStep = () => {
    const { step } = this.state
    this.setState({
      step : step + 1
    })
  }
  
  prevStep = () => {
    const { step } = this.state
    this.setState({
      step : step - 1
    })
  }
  
  render() {

    const { step, uniqueId } = this.state;
    const {handleChange, lead, setFinalStep } = this.props;

    const {
      helpMe,
      borrow,
      houseValue,
      helpWith,
      loanSeek,
      annualIncome,
      //creditHistory,
      currentJob,
      currentlyWork,
      fullName,
      // firstName,
      // lastName,
      emailTo,
      bestTime,
      contactNumber,
      postcode,
    } = this.state;

    const values = {
      helpMe,
      borrow,
      houseValue,
      helpWith,
      loanSeek,
      annualIncome,
      //creditHistory,
      currentJob,
      currentlyWork,
      fullName,
      // firstName,
      // lastName,
      emailTo,
      bestTime,
      contactNumber,
      postcode,
    }

    switch(step) {
      case 1:
        return (
          <FirstStep 
            lead={lead}
            values={values}
            uniqueFieldId={uniqueId}
            nextStep={this.nextStep}
            handleChange={handleChange}
          /> 
        )
      case 2:
        return (
          <SecondStep
            lead={lead}
            values={values} 
            uniqueFieldId={uniqueId}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={handleChange}
          />
        )
        // Our third step is our confirm stage
      case 3:
        return (
          <ThirdStep 
            lead={lead}
            values={values}
            uniqueFieldId={uniqueId}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={handleChange}
            setFinalStep={setFinalStep}
          />
        )
    }
  }
    
      
}

export default MultiStepForm
