import React from 'react'
import RenderContent from '../../RenderContent'
import Leads from '../../Leads'
import { ConditionalWrapper } from '../../../utils/ConditionalWrapper'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import './EnquiryHeader.scss'

export const EnquiryHeader = props => {
  return (
    <section className="enquiry-header">
      <div className="wrap">
        <ContentArea {...props} />
        <LeadArea {...props} />
      </div>
    </section>
  )
}

const ContentArea = props => {
  if (!props) return <p>Content Area has no props</p>
  const { title, subTitle, content, titleSelectorElement = 'span' } = props
  return (
    <div className="content-area-wrapper">
      <div className="content-area-inner">
        <ConditionalWrapper
          condition={titleSelectorElement}
          wrapper={children => {
            switch (titleSelectorElement) {
              case 'span':
                return <span className="content-title">{children}</span>
              case 'h1':
                return <h1 className="content-title">{children}</h1>
              case 'h2':
                return <h2 className="content-title">{children}</h2>
              case 'h3':
                return <h3 className="content-title">{children}</h3>
              default:
                return <span className="content-title">{children}</span>
            }
          }}
        >
          {title}
          <br />
          <span className="subtitle">
            {` `}
            {subTitle}
          </span>
        </ConditionalWrapper>
      </div>
      <RenderContent content={content} />
    </div>
  )
}

ContentArea.defaultProps = {
  title: `THE MORTGAGE BROKER THAT'S`,
  subTitle: `GOT YOUR BACK`,
  content: `
    <p>At Loan Monster, we’re always looking out for you. We want you to know all of your options and help you get the right loan for you.</p>
    <p>Looking to refinance? Want to buy your first home? Need an investment loan? No worries. Just sit back, relax and let us take care of it all for you.</p>
    <p>That’s what we’re here for. We can tell you about all the latest offers and promotions and help you consolidate your debt.</p>
  `,
  titleSelectorElement: 'span',
}

ContentArea.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  content: PropTypes.string,
  titleSelectorElement: PropTypes.string,
}

const LeadArea = props => {
  if (!props) return <div></div>
  const { formId } = props
  const imageData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/monsters/monster-corner.png" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <div className="lead-area-wrapper">
      <div className="image-wrapper">
        <Img
          className="monster-corner"
          loading={'eager'}
          fluid={imageData && imageData.file.childImageSharp.fluid}
        />
      </div>
      <div className="lead-area-inner">
        <Leads id={formId} />
      </div>
    </div>
  )
}
