import React, { useEffect, useState, Component } from 'react'
import fetch from 'isomorphic-fetch'
import { StaticQuery, graphql, navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import * as FormFields from './Fields'
import { FaCircleNotch } from 'react-icons/fa'
import './leads.scss'
import MultiStepForm from '../MultiStepForm/'

// Google TEST Keys
// Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
// Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
class Index extends Component {
  // Create our ref for our Recaptcha component
  recaptchaRef = React.createRef()

  constructor(props) {
    const { thankYou = null, data, id = null } = props
    const { siteSettings, allWordpressWpForm } = data

    let settings =
      id &&
      allWordpressWpForm &&
      allWordpressWpForm.nodes.filter(i => i['wordpress_id'] === parseInt(id))
    settings =
      settings && settings[0] && settings[0].formJson && settings[0].formJson

    super(props)

    this.state = {
      error: null,
      loading: false,
      submitted: false,
      ready: false,
      readyStatus: true,
      id: id,
      thankYou: thankYou,
      siteKey: siteSettings.options.googleSiteKey,
      settings: settings && settings.formFields && settings.formFields,
      lead: {
        location:
          typeof window !== `undefined` ? window.location.href : 'Server',
        recipientAddress:
          settings && settings.recipientAddress
            ? settings.recipientAddress
            : null,
        formId: id,
        helpMe: '',
        borrow: '',
        houseValue: '',
        helpWith: '',
        loanSeek: '',
        annualIncome: '',
        //creditHistory: '',
        currentJob: '',
        currentlyWork: '',
        fullName: '',
        // firstName: '',
        // lastName: '',
        emailTo: '',
        bestTime: '',
        contactNumber: '',
        postcode: '',
      },
      captchaToken: null,
      formHoveredOver: false,
      isMultiPartForm: false,
      formFields: [],
      multiFormEmailRecipients: [],
    }
  }

  componentDidMount() {
    // check for our multi part form
    if (this.props.data) {
      const { allWordpressWpForm } = this.props.data
      // we need to make sure we are setting the form on the page
      allWordpressWpForm.nodes
        .filter(node => node.wordpress_id == this.props.id)
        .map(node => {
          if (node.acf) {
            if (node?.acf?.multi_part_form) {
              this.setState({
                isMultiPartForm: true,
                formFields: node.acf.form_fields,
                multiFormEmailRecipients: node.acf.form_recipient,
              })
            }
          }
        })
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { captchaToken } = this.state
    const token = await this.recaptchaRef.current.executeAsync()
    if (token) {
      this.setState({ readyStatus: true })
      this.setState({ captchaToken: captchaToken })
      this.captchaSubmit(e)
    } else {
      this.setState({ readyStatus: false })
    }
  }
  captchaSubmit = async e => {
    this.setState({ loading: true })
    window.dataLayer = window.dataLayer || []

    const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
    const appSecret = process.env.GATSBY_WEB_APP_SECRET
    const { lead, isMultiPartForm, multiFormEmailRecipients } = this.state

    const { location } = lead
    /**
     * Submit our leads object to our WP leads handling endpoint
     * If you receive an error, double check the keys between this app and the website.
     * An invalid key on WP and in Gatsby may result in a 502.
     *
     * We pass in a multipart form value so that we can flag our endpoint
     */

    // Turn our array of multiple email recipients into once nice long string for PHP wp_mail
    let emailStr = ''
    if (multiFormEmailRecipients) {
      multiFormEmailRecipients.forEach(multiEmail => {
        emailStr += `${multiEmail.email},`
      })
    }
    // finally, add our "emailTo" to the emailStr so we are sending a copy to our form user as well.
    if (emailStr && lead.emailTo) {
      emailStr += `${lead.emailTo.trim()}`
    }

    fetch(leadFunctionUrl, {
      method: 'POST',
      body: JSON.stringify({
        secret: appSecret,
        lead: lead,
        isMultiPartForm: isMultiPartForm,
        multiFormEmailRecipients: emailStr,
      }),
    })
      .then(response => {
        if (response.status === 200) {
          window.dataLayer.push({
            event: 'form_submit_success',
            formLocation: location,
          })
          this.setState({ loading: false, submitted: true, lead: {} })
          this.props.thankYou &&
            this.props.thankYou !== '' &&
            navigate(this.props.thankYou)
        } else {
          this.setState({ loading: false, error: true, lead: {} })
        }
      })
      .catch(error => {
        console.error('Error in submitting the form. :(', error)
        this.setState({ loading: false, error: true })
      })
  }

  hanldeMultiPartFormChange(e) {
    let lead = this.state.lead
    lead[e.target.name] = e.target.value
    this.setState({ lead })
  }

  //Form change
  handleChange(e) {
    let lead = this.state.lead
    //If file
    if (e.target.type === 'file') {
      let fileName = `lead-file-${e.target.name}`
      let file = e.target.files[0]
      lead[fileName] = { data: '', file: '' }

      if (file) {
        const reader = new FileReader(file)
        reader.readAsDataURL(file)
        reader.onload = () => {
          // set image and base64'd image data in component state
          lead[fileName].data = reader.result
          lead[fileName].file = file.name
        }
      }
    } else if (e.target.type === 'checkbox') {
      //Toggle value on off
      lead[e.target.name] === e.target.value
        ? (lead[e.target.name] = '')
        : (lead[e.target.name] = e.target.value)
    } else {
      //All other fields
      lead[e.target.name] = e.target.value
    }
    this.setState({ lead })
  }

  handleFormHover() {
    if (!this.state.formHoveredOver) {
      this.setState({ formHoveredOver: true })
    } else {
      return null
    }
  }

  render() {
    const {
      loading,
      submitted,
      error,
      lead,
      siteKey,
      id,
      settings,
      readyStatus,
      formHoveredOver,
      isMultiPartForm,
    } = this.state

    if (!id) return 'No form id!'

    if (!settings && !isMultiPartForm)
      return 'There is a problem with the JSON!'

    if (submitted)
      return (
        <div className="lead-form thank-you">
          <h3>Thank you!</h3>
          <p>Your message has been sent.</p>
        </div>
      )

    const formatComponentName = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return (
      <div
        className="lead-form"
        onMouseEnter={() => this.handleFormHover()}
        onTouchStart={() => this.handleFormHover()}
      >
        {error && (
          <div className="error">
            <div>
              There was an error submitting the form. :(. Please try and refresh
              the page!
            </div>
          </div>
        )}
        {loading && (
          <div className="loading">
            <div>
              <FaCircleNotch className="spin" /> Submitting...
            </div>
          </div>
        )}
        <form
          className={`lead-form-inner${isMultiPartForm ? ' multi-form' : ''}`}
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          {isMultiPartForm ? (
            <MultiPartFormWrapper
              ready={this.state.ready}
              handleChange={e => this.handleChange(e)}
              readyStatus={readyStatus}
              className="form-inner"
              lead={lead}
            />
          ) : (
            <div className="form-inner">
              {settings.map((field, index) => {
                const FormComponent =
                  FormFields[formatComponentName(field.type)]
                // Map each field into the form component
                return (
                  <FormComponent
                    key={index}
                    field={field}
                    value={lead}
                    handleChange={e => this.handleChange(e)}
                  />
                )
              })}
              <div className="disclaimer">
                {`By completing this enquiry form you accept Loan Monster's `}
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>
                .
              </div>
              <div className="captcha-wrapper">
                <button
                  disabled={!readyStatus}
                  className={`submit${this.state.ready ? ' ready' : ' ready'}`}
                >
                  Get started today
                </button>
              </div>
            </div>
          )}
          {formHoveredOver ? (
            <ReCAPTCHA
              size="invisible"
              ref={this.recaptchaRef}
              className="invisible-recaptcha"
              style={{ opacity: 0.7 }} // we don't want the recaptcha icon to appear across the site so visibly
              sitekey={siteKey}
            />
          ) : null}
        </form>
      </div>
    )
  }
}

const MultiPartFormWrapper = props => {
  const { className, readyStatus, ready, handleChange, lead } = props

  const [finalStep, setFinalStep] = useState(false)
  return (
    <>
      <MultiStepForm
        lead={lead}
        handleChange={handleChange}
        setFinalStep={setFinalStep}
        className={className}
      />
      {finalStep ? (
        <div className="captcha-wrapper">
          <button
            disabled={!readyStatus}
            className={`submit${ready ? ' ready' : ' ready'}`}
          >
            Get started today
          </button>
        </div>
      ) : null}
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        }
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              formFields {
                type
                required
                placeholder
                options {
                  label
                  value
                }
                name
                className
              }
            }
            acf {
              multi_part_form
              form_recipient {
                email
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
