import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import * as yup from 'yup' // for everything

/*
 * Set the form submit status ready in here
 */
export const ThirdStep = props => {
  const {
    lead,
    prevStep,
    uniqueFieldId,
    handleChange,
    nextStep,
    setFinalStep,
  } = props

  let schema = yup.object().shape({
    fullName: yup.string().required('Required'),
    // firstName: yup.string().required('Required'),
    // lastName: yup.string().required('Required'),
    emailTo: yup.string().required('Required'),
    bestTime: yup.string().required('Required'),
    contactNumber: yup.string().required('Required'),
    postcode: yup.string().required('Required'),
  })

  const {
    fullName,
    // firstName,
    // lastName,
    emailTo,
    bestTime,
    contactNumber,
    postcode,
  } = lead

  const [errorStack, setErrorStack] = useState({
    fullName: false,
    // firstName: false,
    // lastName: false,
    emailTo: false,
    bestTime: false,
    contactNumber: false,
    postcode: false,
  })

  function setErrorsToFalse() {
    setErrorStack({
      fullName: false,
      // firstName: false,
      // lastName: false,
      emailTo: false,
      bestTime: false,
      contactNumber: false,
      postcode: false,
    })
  }

  useEffect(() => {
    setFinalStep(true)
  }, [])

  const [errorsActive, setErrorsActive] = useState(false)

  const handleNext = e => {
    e.preventDefault()
    schema
      .validate(
        {
          fullName,
          // firstName,
          // lastName,
          emailTo,
          bestTime,
          contactNumber,
          postcode,
        },
        { abortEarly: false }
      )
      .then(valid => {
        if (valid) {
          setErrorsActive(false)
          // Set state in form wrapper to show the submission button
          nextStep()
        }
      })
      .catch(error => {
        setErrorsActive(true)
        setErrorsToFalse()
        error.inner.map(err => {
          let stateValue = err.params.path
          setErrorStack(prevState => ({ ...prevState, [stateValue]: true }))
        })
      })
  }

  const handlePrevious = e => {
    setFinalStep(false)
    prevStep()
  }

  const errorMessage = `This field is required!`

  return (
    <div className="form-inner">
      <div
        className={errorsActive && errorStack.fullName ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.fullName && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor="full-name">Full Name:*</label>
        <input
          required
          value={lead?.fullName}
          onChange={handleChange}
          name="fullName"
          type="text"
          placeholder="Your full name"
        />
      </div>
      {/* <div
        className={errorsActive && errorStack.firstName ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.firstName && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor="first-name">First Name:*</label>
        <input
          required
          value={lead?.firstName}
          onChange={handleChange}
          name="firstName"
          type="text"
          placeholder="Your first name"
        />
      </div>
      <div
        className={errorsActive && errorStack.lastName ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.lastName && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor="last-name">Last Name:*</label>
        <input
          required
          value={lead?.lastName}
          onChange={handleChange}
          name="lastName"
          type="text"
          placeholder="Your last name"
        />
      </div> */}
      <div
        className={errorsActive && errorStack.emailTo ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.emailTo && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor="emailTo">Email:*</label>
        <input
          required
          value={lead?.emailTo}
          onChange={handleChange}
          name="emailTo"
          type="email"
          placeholder="Your email address"
        />
      </div>
      <div
        className={errorsActive && errorStack.bestTime ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.bestTime && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`bestTime-${uniqueFieldId}`}>
          <strong>Best Time To Call:*</strong>
        </label>
        <select
          id={`bestTime-${uniqueFieldId}`}
          required
          value={lead?.bestTime}
          onChange={handleChange}
          name="bestTime"
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="ASAP">ASAP</option>
          <option value="9am - 12pm">9am - 12pm</option>
          <option value="12pm - 3pm">12pm - 3pm</option>
          <option value="3pm - 6pm">3pm - 6pm</option>
          <option value="6pm - 9pm">6pm - 9pm</option>
        </select>
      </div>
      <div
        className={
          errorsActive && errorStack.contactNumber ? 'errors-active' : ''
        }
      >
        {errorsActive && errorStack.contactNumber && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor="contact-number">Contact Number:*</label>
        <input
          required
          value={lead?.contactNumber}
          onChange={handleChange}
          name="contactNumber"
          type="text"
          placeholder="Your best contact number"
        />
      </div>
      <div
        className={errorsActive && errorStack.postcode ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.postcode && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor="postcode">Postcode:*</label>
        <input
          required
          value={lead?.postcode}
          maxLength="8"
          onChange={handleChange}
          name="postcode"
          type="text"
          placeholder="Your postcode"
        />
      </div>
      <div className="multi-form--button-wrapper">
        <a
          className="button action action-previous"
          onClick={() => handlePrevious()}
        >
          Back
        </a>
      </div>
      <span className="disclaimer">
        By completing this enquiry form you accept Loan Monster’s{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </span>
    </div>
  )
}
