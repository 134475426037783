import React from 'react'
import RenderContent from '../../../../RenderContent';
import { useGlobalCTA } from '../../../../HOOKS/useGlobalCTA'
import { Image } from '../../../../Image'
import LoanMonsterLogo from '../../../../SVG/LoanMonsterLogo'
import '../../../CTA/CTA.scss'
import './GlobalCTA.scss'

export const GlobalCTA = (props) => {
  const {
    title,
    subTitle,
    content,
    image,
    showLogo
  } = useGlobalCTA();
  return (
    <section className="cta-block global-cta">
      <div className="wrap">
        <div className="title-content-wrapper">
          <div className="cta-title-block">
            <span>
              {title}{' '}{subTitle && <><br /><span className="subtitle">{subTitle}</span></>}
            </span>
          </div>
          <div className="cta-content-block">
            <RenderContent content={content} />
          </div>
          {showLogo &&
            <div className="cta-logo-wrapper">
              <LoanMonsterLogo />
            </div>}
        </div>
        <div className="image-wrapper">
          <Image src={image} />
        </div>
      </div>
    </section>
  )
}

// export const GlobalCTAHook
