import React, { useState, useEffect } from 'react'
import * as yup from 'yup' // for everything

export const SecondStep = props => {
  let schema = yup.object().shape({
    loanSeek: yup.string().required('Required'),
    annualIncome: yup.string().required('Required'),
    //creditHistory: yup.string().required('Required'),
    currentJob: yup.string().required('Required'),
    currentlyWork: yup.string().required('Required'),
  })

  const { lead, uniqueFieldId, prevStep, nextStep, handleChange } = props

  const {
    loanSeek,
    annualIncome,
    //creditHistory,
    currentJob,
    currentlyWork,
  } = lead

  const [errorStack, setErrorStack] = useState({
    loanSeek: false,
    annualIncome: false,
    //creditHistory: false,
    currentJob: false,
    currentlyWork: false,
  })

  function setErrorsToFalse() {
    setErrorStack({
      loanSeek: false,
      annualIncome: false,
      //creditHistory: false,
      currentJob: false,
      currentlyWork: false,
    })
  }

  const [errorsActive, setErrorsActive] = useState(false)

  const handleNext = e => {
    e.preventDefault()
    schema
      .validate(
        {
          loanSeek,
          annualIncome,
          //creditHistory,
          currentJob,
          currentlyWork,
        },
        { abortEarly: false }
      )
      .then(valid => {
        if (valid) {
          setErrorsActive(false)
          nextStep()
        }
      })
      .catch(error => {
        setErrorsActive(true)
        setErrorsToFalse()
        error.inner.map(err => {
          let stateValue = err.params.path
          setErrorStack(prevState => ({ ...prevState, [stateValue]: true }))
        })
      })
  }

  const errorMessage = `This field is required!`

  return (
    <div className="form-inner">
      <div
        className={errorsActive && errorStack.loanSeek ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.loanSeek && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`loanSeek-${uniqueFieldId}`}>
          This loan you seek will be:*
        </label>
        <select
          id={`loanSeek-${uniqueFieldId}`}
          value={lead?.loanSeek}
          onChange={handleChange}
          required
          name="loanSeek"
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="In my name only">In my name only</option>
          <option value="A joint loan">A joint loan</option>
          <option value="With more than 2 people">
            With more than 2 people
          </option>
        </select>
      </div>
      <div
        className={
          errorsActive && errorStack.annualIncome ? 'errors-active' : ''
        }
      >
        {errorsActive && errorStack.annualIncome && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`annualIncome-${uniqueFieldId}`}>
          My/Our annual income is:*
        </label>
        <select
          id={`annualIncome-${uniqueFieldId}`}
          value={lead?.annualIncome}
          onChange={handleChange}
          required
          name="annualIncome"
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="$0 - 50K">$0 - $50K</option>
          <option value="$50K - $80K">$50K - $80K</option>
          <option value="$80K - $120K">$80K - $120K</option>
          <option value="$120K - $150K">$120K - $150K</option>
          <option value="$150K - $180K">$150K - $180K</option>
          <option value="$180K +">$180K +</option>
        </select>
      </div>
      {/* <div className={errorsActive && errorStack.creditHistory ? 'errors-active' : ''}>
        {errorsActive && errorStack.creditHistory && <span className="error-msg">{errorMessage}</span>}
        <label htmlFor={`creditHistory-${uniqueFieldId}`}>Your current credit history is:*</label>
        <select id={`creditHistory-${uniqueFieldId}`} value={lead?.creditHistory} onChange={handleChange} required name="creditHistory">
          <option value="" disabled defaultValue>Select your option ...</option>
          <option value="Good">Good</option>
          <option value="OK">OK</option>
          <option value="A few minor issues">A few minor issues</option>
          <option value="Not sure">Not sure</option>
        </select>
      </div> */}
      <div
        className={errorsActive && errorStack.currentJob ? 'errors-active' : ''}
      >
        {errorsActive && errorStack.currentJob && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`currentJob-${uniqueFieldId}`}>
          You have been in your current job for:*
        </label>
        <select
          id={`currentJob-${uniqueFieldId}`}
          value={lead?.currentJob}
          onChange={handleChange}
          required
          name="currentJob"
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="less than 3 months">Less than 3 months</option>
          <option value="3 to 6 months">3 to 6 months</option>
          <option value="6 to 12 months">6 to 12 months</option>
          <option value="Over 1 year">Over 1 year</option>
        </select>
      </div>
      <div
        className={
          errorsActive && errorStack.currentlyWork ? 'errors-active' : ''
        }
      >
        {errorsActive && errorStack.currentlyWork && (
          <span className="error-msg">{errorMessage}</span>
        )}
        <label htmlFor={`currentlyWork-${uniqueFieldId}`}>
          I/We currently work:*
        </label>
        <select
          id={`currentlyWork-${uniqueFieldId}`}
          value={lead?.currentlyWork}
          onChange={handleChange}
          required
          name="currentlyWork"
        >
          <option value="" disabled defaultValue>
            Select your option ...
          </option>
          <option value="Full-time">Full-time</option>
          <option value="Part-time">Part-time</option>
          <option value="Casual">Casual</option>
          <option value="Self-employed">Self-employed</option>
        </select>
      </div>
      <div className="multi-form--button-wrapper">
        <a className="button action action-previous" onClick={() => prevStep()}>
          Back
        </a>
        <a className="button action" onClick={e => handleNext(e)}>
          Next
        </a>
      </div>
    </div>
  )
}
